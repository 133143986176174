// Composable for saving a ref variable to a cookie, and updating the cookie when the ref variable changes
// Useful for saving the user's preferences to cookies (like in the sidebar fast options)

export function useCookieSaving<T>(cookieName: string, refVariable: Ref<T>): void {
  // Getting the userId
  const user = useSupabaseUser()

  // Loading the saved value from cookie (and using its value if necessary)
  const savedValue = useCookie<T>(`user-pref-${cookieName}-${user.value?.id ?? '0'}`, {
    default: () => refVariable.value,
    expires: new Date(Date.now() + 365 * 24 * 60 * 60 * 1000),
  })
  if (savedValue.value !== refVariable.value) {
    refVariable.value = savedValue.value
  }

  // Updating the saved value when necessary
  watch(refVariable, () => {
    savedValue.value = refVariable.value
  })
}